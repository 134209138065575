<template>
  <div class="wrap">
    <Navbar :leftArrow="false" fixed>
      <template #title>
        <img class="nav_logo" src="@/assets/new/login/logo.png" alt="">
      </template>
    </Navbar>
    <div class="banner_wrap">
      <Banner :radius="0" />
      <div class="notice">
        <van-icon name="volume-o" color="#f2bb33" size="15" class="icon" />
        <div class="text">
          <marquee behavior="scroll" direction="left" scrollamount="20" scrolldelay="400" loop="-1">
            <p>
              <span v-for="(item, index) in noticeArr" :key="index">{{
                item
              }}{{ index === noticeArr.length - 1 ? "" : "，" }}</span>
            </p>
          </marquee>
        </div>
      </div>
    </div>
    <div class="btns_wrap">
      <div class="btn_item" @click="toRecharge">
        <img src="@/assets/home/tools/recharge.png" alt="">
        <div class="text">{{ $t("topUpNow") }}</div>
      </div>
      <div class="btn_item" @click="toWithdraw">
        <img src="@/assets/home/tools/withdraw.png" alt="">
        <div class="text">{{ $t("balanceWithdrawal") }}</div>
      </div>
      <div class="btn_item" @click="toPage('bettingRecord')">
        <img src="@/assets/home/tools/personal.png" alt="">
        <div class="text">{{ $t("user.bettingRecord") }}</div>
      </div>
      <div class="btn_item" @click="toService">
        <img src="@/assets/home/tools/service.png" alt="">
        <div class="text">{{ $t("user.service") }}</div>
      </div>
    </div>
    <!-- <div class="daily">
      <div class="daily_gift">
        <div class="daily_wel">{{ $t('weclomeId') }}: <span>{{ userInfo.username }}</span>
        </div>
        <div class="daily_money">{{ userInfo.money }}<span>{{ verConfig.moneyType }}</span></div>
      </div>
      <div class="util_bar">
        <div class="bar_item" v-for="item, index in bars" :key="index" @click="toPage(item.url)">
          <img class="img" src="../../assets/home/deposit.png" alt="" v-if="index == 0">
          <img class="img" src="../../assets/home/withdraw.png" alt="" v-if="index == 1">
          <img class="img" src="../../assets/home/ac.png" alt="" v-if="index == 2">
          <div class="bar_text">{{ $t(item.text) }}</div>
        </div>
      </div>
    </div> -->
    <div class="shop_wrap">
      <div class="content_wrap">
        <div class="wrap_title">{{ $t('seriesColor') }}</div>
        <div class="merchants_wrap">
          <div class="merchant_item" v-for="(item, index) in merchants" :key="index" @click="toGame(item)">
            <img :src="item.picture" alt="" />
            <div class="subtitle">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="hall">
      <div class="title">
        {{ $t("Home.HallofFame") }}
      </div>
      <HallMarquee class="marquee" :height="208">
        <template v-slot="{ item }">
          <div class="item">
            <div class="left">
              <Avatar size="40" :src="$img(
            `/uploads/system/userimg/userimg_${Math.round(
              Math.random() * 10
            )}.png?x`
          )
            " :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
              <div class="info">
                <div class="nick_name">
                  {{ item.name }}
                </div>
                <div class="text">
                  {{ $t("successWithdrawof") }}
                </div>
              </div>
            </div>
            <div class="right">+{{ $money(item.money, false, { moneyType: item.moneyType }) }}</div>
          </div>
        </template>
</HallMarquee>
</div> -->
    <van-dialog v-model="dialogShow" :showConfirmButton="false" close-on-click-overlay>
      <img style="width: 100%" :src="homeImg" />
      <div class="close">
        <van-icon class="icon" name="close" @click="dialogShow = false" />
      </div>
    </van-dialog>
    <div class="win_content">
      <van-dialog v-model="dialogContent" close-on-click-overlay :confirm-button-text="$t('btn.confirm')"
        confirm-button-color="#ee0a24" @confirm="winDialogConfirm" @close="winDialogConfirm">
        <template #title>
          <div class="win_title">{{ winContent.title }}</div>
        </template>
        <div class="win_contents" v-html="winContent.content"></div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import LoadingRE from "@/components/LoadingRE";
import VueClipboard from "vue-clipboard2";
import { Toast, Badge } from "vant";
import Cookies from "js-cookie";
import { GET_CONFIG_INFO, UNREAD_NUM } from "@tt/api";
import { Swipe, SwipeItem, Collapse, CollapseItem, Cell, Icon, Popup, ImagePreview, Dialog } from "vant";
import dataDetail from "@/utils/dataDetail";
import verConfig from "@/utils/verConfig";
import { $post } from "@/utils/request";
import Banner from "@/components/banner.vue";
import { Avatar } from "@tt/components";
// import Avatar from "@/components/avatar";
import HallMarquee from "@/components/hallMarquee.vue";
import { HOME_IMG, WINNOTICE } from "@/api"
import Navbar from "@/components/JNav";
export default {
  components: {
    Popup,
    Tabbar,
    LoadingRE,
    VueClipboard,
    Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    Banner,
    Avatar,
    HallMarquee,
    [Icon.name]: Icon,
    Badge,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    Navbar
  },
  data() {
    return {
      dialogShow: false,
      homeImg: '',
      verConfig,
      islocalImg: true,
      userInfo: {
        money: 0,
        frozen_price: 0,
        complete_total_price: 0,
        complete_yesterday_price: 0,
        frozen_rate_price: 0,
      },
      country: Cookies.get("language"),
      bannerArr: [],
      noticeArr: [],
      inimenuArr: [{
        title: this.$t("newAdd.missionHall"),
        img: require("../../assets/icon/1.png"),
        activeImg: require("../../assets/icon/1-active.png"),
      }, {
        title: this.$t("newAdd.helpCenter"),
        img: require("../../assets/icon/3.png"),
        activeImg: require("../../assets/icon/3-active.png"),
      }, {
        title: this.$t("newAdd.inviteFriends"),
        img: require("../../assets/icon/7.png"),
        activeImg: require("../../assets/icon/7-active.png"),
      }],
      menuArr: [],
      noticeArrss: [],
      menuVal: 0,
      allowed: 0,
      gradeArr: [{
        title: "",
        rate: "",
      }, {
        title: "",
        rate: "",
      }, {
        title: "",
        rate: "",
      }, {
        title: "",
        rate: "",
      }],
      helpData: [],
      activeNames: 0,
      service_url: "",
      loadingNum: 0,
      noticeShowIndex: 3,
      unread: 0,
      ruleIsShow: false,
      bars: [{
        url: 'NewRecharge',
        text: 'My.MemberInformation.Recharge'
      }, {
        url: 'newWithdraw',
        text: 'My.MemberInformation.Withdraw'
      }, {
        url: 'user',
        text: 'newTab.my'
      }],
      dialogContent: false,
      winContent: {}
    };
  },
  computed: {
    merchants() {
      const arr = this.$store.getters.AllGameList.filter(item => item.room_status == 1)
      return arr;
    },
    winNum() {
      return this.$store.getters.winNum
    }
  },
  methods: {
    winDialogConfirm() {
      this.$store.dispatch('getWinNum', false);
    },
    toPage(url) {
      if (!url) return;
      if (url == 'NewRecharge') return this.$store.dispatch("toService");
      this.$router.push({ name: url });
    },
    async getHome() {
      this.loadingNum++;
      try {
        const { data } = await HOME_IMG();
        this.loadingNum--;
        if (data && data.length > 0) {
          this.homeImg = data[0].picture;
          this.dialogShow = true
        } else {
          this.homeImg = ''
          this.dialogShow = false
        }
      } catch (error) {
        this.loadingNum--;
        this.homeImg = ''
        this.dialogShow = false
      }
    },
    async getWinCont() {
      this.loadingNum++;
      try {
        const { data } = await WINNOTICE();
        this.loadingNum--;
        this.winContent = data.length && data[0]
        if (data.length && data[0]) {
          this.dialogContent = true;
        }
      } catch (error) {
        this.loadingNum--;
      }
    },
    showRule() {
      // this.ruleIsShow = true;
      ImagePreview([require('@/assets/home/rule.png')]);
    },
    async getUnread() {
      this.loadingNum++;
      try {
        const res = await UNREAD_NUM();
        res && (this.unread = res);
        this.loadingNum--;
      } catch (error) {
        this.loadingNum--;
      }
    },
    // add:点击充值的按钮
    RechargeEvent() {
      this.$router.push("/new-recharge");
    },
    // add 分享
    goshare() {
      this.$router.push("/inviteFriends");
    },
    // add:点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // add:去下载
    downloadEvent() {
      if (localStorage.getItem("android")) {
        location.href = localStorage.getItem("android");
      } else {
        location.href = "https://www.google.com/";
      }
    },
    // add/随机名字
    randName() {
      let name =
        String(dataDetail.rand(72, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    // add/随机
    randMoney() {
      let probability = dataDetail.rand(0, 100);
      if (probability <= 30) {
        return dataDetail.rand(100, 10000);
      } else {
        return dataDetail.rand(10000, 300000);
      }
    },
    // add:lunbo
    noticeIni() {
      for (let i = 0; i < 12; i++) {
        this.noticeArrss.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
      // console.log(this.noticeArrss);
    },
    // add:lunbo
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArrss.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    toInvite() {
      this.$router.push({ name: "InviteFriends" });
    },
    toRecharge() {
      // Dialog.alert({
      //   title: this.$t('My.MemberInformation.Recharge'),
      //   message: this.$t('hint.lxkf'),
      //   showCancelButton: true,
      //   confirmButtonText: this.$t('Login.goLoginText'),
      //   cancelButtonText: this.$t('newAdd.Cancel')
      // }).then(() => {
      //   this.$api.toService();
      // }).catch(() => {
      // });
      // return
      this.$router.push({ name: "NewRecharge" });
    },
    toService() {
      this.$api.toService();
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    copySuccess() {
      // Toast('Copy success!')
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      // Toast('Copy the failure!')
      Toast(this.$t("newAdd.copyError"));
    },
    menuChange(val) {
      this.menuVal = val;
    },
    handleChange(val) {
    },
    // 获取等级数组
    getLevels() {
      this.$api.getLevel((res) => {
        this.loadingNum--;
        this.gradeArr = res;
      });
    },
    // 获取用户信息
    getUserInfo() {
      this.loadingNum++;
      if (!this.userInfo.level) {
        this.$api.getUserInfo((res) => {
          this.loadingNum--;
          if (res.identity.indexOf("agent") == -1) {
            this.inimenuArr.splice(2, 1);
          }
          this.userInfo = res;
          // Toast.clear()
        }, (err) => {
          // Toast.clear()
          this.loadingNum--;
        });
      }
    },
    toUnlock() {
      this.$router.push({ name: "levelUpgrade" });
    },
    getNotice() {
      this.loadingNum++;
      this.$api.getNotice((res) => {
        this.loadingNum--;
        this.noticeArr.push(res.scroll_notice);
        this.service_url = res.service_url;
        localStorage.setItem("android", res.appurl_android);
        localStorage.setItem("service_url", res.service_url);
      }, err => {
        this.loadingNum--;
      });
    },
    getConfigShare() {
      this.loadingNum++;
      GET_CONFIG_INFO({ act: "share" }).then((r) => {
        this.loadingNum--;
        if (r.data.ret === 1) {
          this.inviteLink = r.data.data.text;
          this.$cookie.set("public-share-item", JSON.stringify(r.data.data));
        } else {
          this.inviteLink = "";
          this.$cookie.set("public-share-item", {});
        }
      }).catch((e) => {
        console.error(e);
        this.loadingNum--;
      });
    }, // 获取分享地址
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getHelpArr() {
      this.loadingNum++;
      this.$api.getHomeHelp(
        (res) => {
          // console.log(res);
          this.loadingNum--;
          for (let i = 0; i < res.lists.length; i++) {
            let res_item = res.lists[i];
            res_item.content = res_item.content.replace(/<[^>]*>/gi, "");
          }
          this.helpData = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    // 获取商家(玩法)数据
    // async getMerchants() {
    //   try {
    //     const res = await $post("/lottery/setting");
    //     const { ret, msg, data } = res.data;
    //     this.merchants = data;
    //     this.$cookie.set("merchants", JSON.stringify(this.merchants));
    //   } catch (error) {}
    // },
    // 进入玩法
    toGame(item) {
      this.$router.push(`/Game?type=${item.type}&category=${item.category}`);
    },
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    if (this.$cookie.get("userInfo")) {
      this.getUserInfo();
    }
    // this.getLevels();
    this.getNotice();
    this.getConfigShare();
    if (this.winNum) {
      this.getWinCont();
    }
    // this.getMenuArr();
    // this.getHelpArr();
    // this.getUnread();
    // this.getHome()
    // this.getMerchants();
  },
  mounted() {
    if (!this.$cookie.get("token")) {
      this.inimenuArr.splice(2, 1);
    }
    this.noticeIni();
    // this.noticeDataUpdate();
  },
  destroyed() {
  },
  watch: {
    loadingNum: (newVal) => {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      if (!newVal) {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
      setTimeout(() => {
        Toast.clear();
      }, 10000)
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/iconfonts/iconfont.css");
// @Color: #e23d6d;
@Color: var(--theme);
@comColor: var(--theme);
@wrapShadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);

.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: @Color;
}

.wrap {
  min-height: calc(100vh - 50px);
  overflow: hidden;

  .nav_logo {
    height: calc(30rem / 16);
    display: block;
  }

  .notice {
    display: flex;
    align-items: center;
    margin: calc(11rem / 16);
    background-color: var(--light);
    box-shadow: 0 2px 4px 0 #D8D8D8;
    padding: 0 calc(10rem / 16);
    height: calc(30rem / 16);
    line-height: calc(30rem / 16);

    >.icon {
      margin-right: 8px;
      font-size: calc(15rem / 16);
      line-height: 1;

      >img {
        width: calc(15rem / 16);
      }
    }

    >.text {
      display: flex;
      font-size: 14px;
      margin-top: -2px;
      line-height: 1;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;

      >marquee {
        >p {
          white-space: nowrap;

          >span {
            margin-right: 30px;
            color: var(--red);
          }
        }
      }
    }
  }

  .banner_wrap {
    overflow: hidden;
  }

  .hall {
    margin: @wrapPadding;
    padding: @wrapPadding;
    background: #fafdff;
    border-radius: @comRadius;

    .title {
      color: #ff7f23;
      margin-bottom: calc(5rem / 16);
    }

    .marquee {
      .item {
        margin-top: @wrapPadding;
        padding: calc(6rem / 16) calc(8rem / 16);
        padding-right: calc(14rem / 16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 99px;
        background-color: #f0f6fa;

        .left {
          display: flex;
          font-size: calc(14rem / 16);

          .info {
            margin-left: calc(4rem / 16);
            padding: 1px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nick_name {
              color: #333;
            }

            .text {
              letter-spacing: 0;
              color: #333;
              margin-top: calc(8rem / 16);
            }
          }
        }

        .right {
          color: #ff7f23;
          font-size: calc(14rem / 16);
          letter-spacing: 0;
          // font-family: PingFangSC-Medium;
        }
      }
    }
  }

  .btns_wrap {
    margin: 0 @wrapPadding @wrapPadding;
    display: flex;
    @itemGap: @wrapPadding;
    background-color: var(--light);
    padding: calc(15rem / 16);
    justify-content: space-between;
    border-radius: calc(4rem / 16);

    .btn_item {
      color: var(--navColor);
      font-size: calc(13rem / 16);
      width: 25%;
      // flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      img {
        width: calc(44rem / 16);
        height: calc(44rem / 16);
        display: block;
      }

      .text {
        margin-top: calc(5rem / 16);
        text-align: center;
        line-height: calc(16rem / 16);
        letter-spacing: -0.18px;
      }
    }
  }

  .daily {
    @color: #7998b1;
    display: flex;
    justify-content: space-between;
    margin-top: calc(5rem / 16);
    padding: calc(7rem / 16) calc(12rem / 16);

    &_gift {
      .daily_wel {
        color: @color;
        font-size: calc(12rem / 16);
      }

      .daily_money {
        font-size: calc(19rem / 16);
        color: var(--light);
        margin-top: calc(5rem / 16);

        span {
          font-size: calc(12rem / 16);
          margin-left: calc(5rem / 16);
        }
      }
    }

    .util_bar {
      display: flex;

      .bar_item {
        margin-left: calc(5rem / 16);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img {
          // width: calc(20rem / 16);
          height: calc(20rem / 16);
          display: block;
        }

        .bar_text {
          color: @color;
          font-size: calc(12rem / 16);
          margin-top: calc(10rem / 16);
        }
      }
    }
  }

  .shop_wrap {
    margin: calc(7rem / 16) @wrapPadding;
    @radius: @comRadius;

    .content_wrap {
      background-color: var(--light);
      border-radius: calc(4rem / 16);

      .wrap_title {
        padding: calc(15rem / 16);
        font-size: var(15rem / 16);
        color: var(--navColor);
        font-weight: 700;
      }
    }

    .merchants_wrap {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #ededed;

      .merchant_item {
        width: calc((100% - 2px) / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: calc(10rem / 16);
        box-sizing: border-box;
        border-right: 1px solid #ededed;
        border-bottom: 1px solid #ededed;

        >img {
          height: calc(62rem / 16);
          width: calc(62rem / 16);
          display: block;
        }

        .subtitle {
          font-size: calc(12rem / 16);
          color: var(--navColor);
          margin-top: calc(5rem / 16);
          text-align: center;
          // min-height: calc(30rem / 16);
        }
      }

      .merchant_item:nth-child(3n) {
        border-right: none;
      }
    }
  }

  .lottery_lobby {
    padding: calc(7rem / 16) calc(12rem / 16);

    .img {
      width: 100%;
      height: calc(163rem / 16);
      display: block;
      border-radius: calc(6rem / 16);
    }
  }
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;

  .icon {
    font-size: 20px;
    color: #000;
  }
}

.win_content {
  /deep/ .van-dialog {
    width: 75%;
    background-color: transparent;

    .van-dialog__header {
      background: var(--red);
      color: #fff;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 0;

      .win_title {
        height: calc(40rem / 16);
        font-size: calc(15rem / 16);
        text-align: center;
        line-height: calc(40rem / 16);
      }
    }

    .win_contents {
      background-color: var(--light);
      padding-bottom: 0;
      box-sizing: border-box;
      min-height: calc(180rem / 16);
      padding: calc(10rem / 16);

      p {
        white-space: normal;
        word-wrap: break-word;
      }

      img {
        max-height: 70vh;
        display: block;
      }
    }

    .van-button {
      border-radius: 0;
    }

    .van-dialog__cancel,
    .van-dialog__confirm {
      height: 50px;
    }

    .van-button--default {
      border: none !important
    }

    // .van-hairline--top::after {
    //   border: none !important;
    // }
  }
}
</style>
